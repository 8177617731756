import type {TranslateOptions} from '~/foundation/I18n/types';

import type {InstallmentsContextType} from '../context/InstallmentsContext';
import type {ModalText, ModalType} from '../types';

import {getSplitPayModalType} from './getModalType';
import {convertPriceToNumber} from './price';

type InstallmentsProps = Pick<
  InstallmentsContextType,
  | 'countryCode'
  | 'eligible'
  | 'fullPrice'
  | 'loanTypes'
  | 'minIneligibleMessageType'
  | 'numberOfPaymentTerms'
>;

type GetSplitPayModalTextProps = InstallmentsProps & {
  lendersLink: JSX.Element;
  priceRange?: {
    minPrice: string;
    maxPrice: string;
  };
};

export const getSplitPayModalText = ({
  countryCode,
  eligible,
  fullPrice,
  lendersLink,
  loanTypes,
  minIneligibleMessageType,
  numberOfPaymentTerms,
  priceRange,
}: GetSplitPayModalTextProps): ModalText => {
  const modalType = getSplitPayModalType({eligible, loanTypes});

  const ineligibleSubtitleKey = getIneligibleSubtitleKey({
    fullPrice,
    priceRange,
    numberOfPaymentTerms,
    minIneligibleMessageType,
  });

  // Set the subtitle key based on the modal type
  const subTitleKey =
    getSubTitleKey({modalType, numberOfPaymentTerms}) ?? ineligibleSubtitleKey;

  // Set the legal copy keys based on the country code and modal type
  const legalCopyKeys = getLegalCopyKeys({
    countryCode,
    lendersLink,
    modalType,
  });

  return {
    subTitleKey,
    legalCopyKeys,
  };
};

interface GetSubTitleKeyProps {
  modalType: ModalType;
  numberOfPaymentTerms: number;
}
const getSubTitleKey = ({
  modalType,
  numberOfPaymentTerms,
}: GetSubTitleKeyProps) => {
  switch (modalType) {
    case 'adaptive': {
      return 'paymentTerms.splitPayContents.subtitle.interestAndSplitPay';
    }
    case 'split_pay': {
      if (numberOfPaymentTerms === 2) {
        return 'paymentTerms.splitPayContents.subtitle.splitPayOnly2';
      } else if (numberOfPaymentTerms === 1) {
        return 'paymentTerms.splitPayContents.subtitle.splitPayOnly30';
      } else {
        return 'paymentTerms.splitPayContents.subtitle.splitPayOnly';
      }
    }
    case 'interest_only': {
      return 'paymentTerms.splitPayContents.subtitle.interestOnly';
    }
    default: {
      return undefined;
    }
  }
};

interface GetLegalCopyKeysProps {
  countryCode?: string;
  lendersLink: JSX.Element;
  modalType: ModalType;
}
const getLegalCopyKeys = ({
  countryCode,
  lendersLink,
  modalType,
}: GetLegalCopyKeysProps) => {
  const affirmUrlKey: [string, TranslateOptions] = [
    'paymentTerms.splitPayContents.legal.ratesFromApr',
    {
      link: lendersLink,
    },
  ];

  switch (countryCode) {
    case 'CA': {
      return ['paymentTerms.splitPayContents.legal.CA'];
    }
    case 'US': {
      switch (modalType) {
        case 'adaptive': {
          return [
            'paymentTerms.splitPayContents.legal.interestAndSplitPay',
            affirmUrlKey,
            'paymentTerms.splitPayContents.legal.caResidentsNotice',
          ];
        }
        case 'split_pay': {
          return [
            'paymentTerms.splitPayContents.legal.splitPayOnly',
            'paymentTerms.splitPayContents.legal.caResidentsNotice',
          ];
        }
        case 'interest_only': {
          return [
            'paymentTerms.splitPayContents.legal.interestOnly',
            affirmUrlKey,
            'paymentTerms.splitPayContents.legal.caResidentsNotice',
          ];
        }
        case 'ineligible': {
          return [
            'paymentTerms.splitPayContents.legal.ineligible',
            'paymentTerms.splitPayContents.legal.caResidentsNotice',
          ];
        }
        default: {
          return [];
        }
      }
    }
    default: {
      return [];
    }
  }
};

type GetIneligibleSubtitleKeyProps = Pick<
  InstallmentsContextType,
  'minIneligibleMessageType' | 'numberOfPaymentTerms' | 'fullPrice'
> & {
  priceRange?: {
    minPrice: string;
    maxPrice: string;
  };
};
const getIneligibleSubtitleKey = ({
  minIneligibleMessageType,
  numberOfPaymentTerms,
  fullPrice,
  priceRange,
}: GetIneligibleSubtitleKeyProps): string => {
  const minPriceNumber = priceRange?.minPrice
    ? convertPriceToNumber(priceRange.minPrice)
    : null;
  const priceNumber = fullPrice ? convertPriceToNumber(fullPrice) : null;

  if (!minPriceNumber || !priceRange?.maxPrice) {
    return '';
  }

  if (!priceNumber || priceNumber < minPriceNumber) {
    let translationKey = 'paymentTerms.splitPayContents.subtitle.ineligibleMin';

    if (numberOfPaymentTerms === 2 || numberOfPaymentTerms === 1) {
      translationKey =
        'paymentTerms.splitPayContents.subtitle.ineligibleMinOverTime';
    }

    const ineligibleMonthlySubtitleKey =
      'paymentTerms.splitPayContents.subtitle.ineligibleMonthlyPaymentsMin';

    return minIneligibleMessageType === 'monthly'
      ? ineligibleMonthlySubtitleKey
      : translationKey;
  }

  const subTitleKey = 'paymentTerms.splitPayContents.subtitle.ineligibleMax';

  return subTitleKey;
};
